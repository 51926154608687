import React from 'react';

import {
  EditorColumn,
} from 'leeloo-react/component/dataTable/column/EditorColumn';
import {
  SimpleColumn,
} from 'leeloo-react/component/dataTable/column/SimpleColumn';
import { DataTable } from 'leeloo-react/component/dataTable/DataTable';
import { Filter } from 'leeloo-react/component/filter/Filter';
import { InputField } from 'leeloo-react/component/form/field/InputField';
import { SelectField } from 'leeloo-react/component/form/field/SelectField';
import {
    DataSourceType,
} from 'leeloo-react/component/hook/useDataSource';

import { PresenceEnum } from '../../../../enum/PresenceEnum';
import { DataTableDeleteButton } from '../../../dataTable/button/DataTableDeleteButton';
import { LengthColumn } from '../../../dataTable/column/LengthColumn';
import { DataTableBulksActions } from '../../../dataTable/DataTableBulksActions';
import { DataTableHeaderActions } from '../../../dataTable/DataTableHeaderActions';
import { UpdatePresence } from '../../../form/UpdatePresence';

type ParticipantDatatableProps = {
    dsParticipants: DataSourceType,
    resourcePresence: string,
    addRoute: string,
    idEntity: string,
}

export function ParticipantDatatable(props: ParticipantDatatableProps) {
  return (
    <DataTable
      dataSource={props.dsParticipants}
      filter={(
        <Filter>
          <InputField name="search" label="Rechercher" />
        </Filter>
      )}
      actionHeader={(
        <DataTableHeaderActions
          addRoute={props.addRoute}
          addRouteParams={{ idEntity: props.idEntity }}
          addRouteValue="Ajouter des participants"
          dataSource={props.dsParticipants}
        />
      )}
      actionRow={(row) => [
        <DataTableDeleteButton
          key={0}
          row={row}
          dataSource={props.dsParticipants}
          resource={props.resourcePresence}
        />,
      ]}
      onChangeCheckedRow={(rows: { id: number | string }[], cleanSelection) => (
        <>
          <UpdatePresence
            rows={rows}
            fieldName="presence"
            resource={props.resourcePresence}
            dataSource={props.dsParticipants}
          />
          <DataTableBulksActions
            rows={rows}
            dataSource={props.dsParticipants}
            deleteResource={props.resourcePresence}
            cleanSelection={cleanSelection}
            data={{ idEntity: props.idEntity }}
          />
        </>
      )}
    >
      <SimpleColumn field="contactNom" title="Nom" sortable />
      <SimpleColumn field="contactPrenom" title="Prénom" sortable />
      <SimpleColumn field="mailcontact" title="Email" sortable />
      <SimpleColumn field="agencymain" title="Agent" sortable />
      <LengthColumn field="agenceEmail" title="Email agent" sortable />
      <SimpleColumn field="offre" title="Offre" sortable />
      <SimpleColumn field="metier" title="Role" sortable />
      <EditorColumn
        resource={props.resourcePresence}
        field="presence"
        title="Présence"
        columnElement={SimpleColumn}
        inputElement={<SelectField optionsEnum={PresenceEnum} isClearable={false} />}
        forceEditable
      />
    </DataTable>
  );
}
